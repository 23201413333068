html, body {
    height: 100%;
    min-height: 100% !important;
    margin: 0;
    position: relative;
}

body * {
    box-sizing: border-box;
}

#root, .App {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
}

/* react-confirm-alert creates svg when dialog box shows. It appears on the body, outside the root div.
 * Due to being outside the root, it changes the page structure. It's unused, so just don't display it.*/
.react-confirm-alert-svg {
  display: none;
}
